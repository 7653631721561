<template>
   <!-- 业绩奖励列表 -->
   <a-card :bordered='false'>
      <a-row class="query-row" type='flex' :gutter="12">
         <a-col style="width:200px">
           <span>受益人：</span>
           <a-input v-model="queryData.mobile" allowClear placeholder='请输入'></a-input>
        </a-col>
         <a-col style="width:200px">
           <span>分享人：</span>
           <a-input v-model="queryData.shareMobile" allowClear placeholder='请输入'></a-input>
        </a-col>
         <a-col style="width:200px">
           <span>下单人：</span>
           <a-input v-model="queryData.buyerMobile" allowClear placeholder='请输入'></a-input>
        </a-col>
         <a-col style="width:220px">
           <span>主订单号：</span>
           <a-input v-model="queryData.orderBn" allowClear placeholder='请输入'></a-input>
        </a-col>
        <a-col style="width:220px">
           <span>子订单号：</span>
           <a-input v-model="queryData.orderSn" allowClear placeholder='请输入'></a-input>
        </a-col>

        <a-col style="width:150px">
           <span>状态：</span>
           <a-select v-model="queryData.status" allowClear placeholder='请选择'>
               <a-select-option v-for="(val,key) in status" :key="key" :value="key">{{val}}</a-select-option>
            </a-select>
        </a-col>

        <a-col style="width:200px">
           <span>购买方式：</span>
           <a-select v-model="queryData.referrerId" allowClear placeholder='请选择'>
                <a-select-option v-for="(val,key) in referrerId" :key="key" :value="key">{{val}}</a-select-option>
            </a-select>
        </a-col>

        <a-col style="width:380px">
           <span>奖励发放时间：</span>
           <a-range-picker 
            v-model="sentTime"
            :show-time="showTime"/>
        </a-col>

        <a-col style="width:380px">
           <span>订单支付时间：</span>
           <a-range-picker 
            v-model="payTime"
            :show-time="showTime"/>
        </a-col>
        
        <a-col>
           <a-button @click="() => {this.pageNum = 1;this.loadTableData()}" type='primary' style="margin-right:12px">查询</a-button>
           <a-button type='primary' @click="exportData()">导出</a-button>
        </a-col>
      </a-row>

      <a-table :columns="columns" :dataSource="tableData" :pagination="false" 
         :loading="loading"
         :scroll="{x: true}"
         size="default">
      </a-table>
      <a-pagination
         style="display: flex; flex-direction: row; justify-content: flex-end; margin:10px 10px 5px 0"
         size="small"
         v-model="pageNum"
         :total="total"
         :showTotal="total => `共 ${total} 条`"
         showSizeChanger
         showLessItems
         showQuickJumper
         :pageSize.sync="pageSize"
         @change="loadTableData"
         @showSizeChange="() => {this.pageNum = 1; this.loadTableData()}"
      />

       <ExportModal
         ref="exportModal"
         :exportUrl="exportUrl"
         :params="handleParams"
         :total="total"
      >
      </ExportModal>
   </a-card>
</template>

<script>
import moment from 'moment'
import api from '@/api'
import { buyMethodsNew } from '@/config'
import ExportModal from '@/components/ExportModal'
export default {
   name: 'PerformanceRewardList',
   components: {
      ExportModal
   },
   data () {
      return {
         exportUrl: '/ar-advance/xmallYx/performanceRewardList/expJob',
         showTime:{
            format: 'HH:mm:ss',
            defaultValue:[moment('00:00:00', 'HH:mm:ss'),moment('23:59:59', 'HH:mm:ss')]
         },
         status: {
            '1': '待发放',
            '2': '已发放',
            '3': '已退单',
         },
         pageNum:1,
         total:0,
         pageSize:10,
         loading:false,
         tableData: [],
         queryData: {
            mobile: undefined,
            shareMobile: undefined,
            buyerMobile: undefined,
            orderBn: undefined,
            orderSn: undefined,
            status: undefined,
            referrerId: undefined,
         },
         sentTime: [],
         payTime: [],
         referrerId: buyMethodsNew,
         columns:[
            {
               title: '序号',
               align: 'center',
               ellipsis:true,
               customRender: (text, record, index) => {
                  return index + 1
               }
            },
            {
               title: '受益人',
               dataIndex: 'mobile',
               align: 'center',
               ellipsis:true
            },
            {
               title: '奖励类型',
               dataIndex: 'rewardTypeStr',
               align: 'center',
               ellipsis:true
            },
            {
               title: '积分数值',
               dataIndex: 'rewardAmount',
               align: 'center',
               ellipsis:true
            },
            {
               title: '分享人',
               dataIndex: 'shareMobile',
               align: 'center',
               ellipsis:true,
            },
            {
               title: '下单人',
               dataIndex: 'buyerMobile',
               align: 'center',
               ellipsis:true
            },
            {
               title: '主订单号',
               dataIndex: 'orderBn',
               align: 'center',
               ellipsis:true
            },
            {
               title: '子订单号',
               dataIndex: 'orderSn',
               align: 'center',
               ellipsis:true
            },
            {
               title: '购买方式',
               dataIndex: 'referrerIdStr',
               align: 'center',
               ellipsis:true,
            },
            {
               title: '奖励发放状态',
               dataIndex: 'statusStr',
               align: 'center',
               ellipsis:true,
            },
            {
               title: '奖励发放时间',
               dataIndex: 'sentTimeStr',
               align: 'center',
               ellipsis:true,
            },
            {
               title: '订单支付时间',
               dataIndex: 'payTimeStr',
               align: 'center',
               ellipsis:true,
            }
         ]
      }
   },
   computed: {
      handleParams () {
         let p = {
            ...this.queryData,
            pageNum: this.pageNum,
            pageSize: this.pageSize
         }
         if (this.sentTime.length) {
            p.sentBeginTime = this.sentTime[0].valueOf() / 1000
            p.sentEndTime = this.sentTime[1].valueOf() / 1000
         }
         if (this.payTime.length) {
            p.payBeginTime = this.payTime[0].valueOf() / 1000
            p.payEndTime = this.payTime[1].valueOf() / 1000
         }
         for (const key in p) {
            if (p[key] == '' || p[key] == undefined) {
               delete p[key]
            }
         }
         return p
      }
   },
   created () {
      this.loadTableData()
   },
   methods: {
      loadTableData () {
         this.loading = true
         api.marketManage.performanceRewardList(this.handleParams).then(res => {
            if (res.code === '00000') {
               this.total = res.data.total
               this.tableData = res.data.dataList
            } else {
               this.$message.error(res.msg)
            }
         }).finally(() => {
            this.loading = false
         })
      },
      exportData () {
         this.$refs.exportModal.show()
      }
   }
}
</script>

<style>

</style>